import React from 'react';
import PropTypes from 'prop-types';
import ButtonStyled from '../../atoms/ButtonStyled/Button';
import style from './ActionsMyProductList.module.scss';
import ButtonWithTooltip from '../ButtonWithTooltip/ButtonWithTooltip';

const ActionsMyProductList = ({
  onClickSeeMyLists,
  onClickSaveMyLists,
  theme,
}) => (
  <div className={style.myProductListActions}>
    <div className={style.myProductListActions__seeButton}>
      <ButtonStyled
        variant="text"
        color="secondary"
        onClick={onClickSeeMyLists}
        theme={theme}
      >
        <a href="https://www.homecenter.com.co/homecenter-co/myaccount/listsAndProjects">
          Ver Mis listas
        </a>
      </ButtonStyled>
    </div>
    <div className={style.myProductListActions__saveButton}>
      <div className={style.myProductListActions__saveButton_noTooltip}>
        <ButtonStyled
          variant="outlined"
          color="secondary"
          onClick={onClickSaveMyLists}
          theme={theme}
        >
          <a href="https://www.homecenter.com.co/homecenter-co/myaccount/listsAndProjects">
            Guardar en Mi lista
          </a>
        </ButtonStyled>
      </div>
      <div className={style.myProductListActions__saveButton_withTooltip}>
        <ButtonWithTooltip
          variant="outlined"
          color="secondary"
          theme={theme}
          onClick={onClickSaveMyLists}
          tooltipText="Si aun no vas a comprar este producto, puedes guardarlo en tu lista y volver a revisarlo la próxima vez que entres al sitio"
        >
          <a href="https://www.homecenter.com.co/homecenter-co/myaccount/listsAndProjects">
            Guardar en Mi lista
          </a>
        </ButtonWithTooltip>
      </div>
    </div>
  </div>
);

ActionsMyProductList.propTypes = {
  onClickSeeMyLists: PropTypes.func,
  onClickSaveMyLists: PropTypes.func,
  theme: PropTypes.shape(PropTypes.shape({})),
};

export default ActionsMyProductList;

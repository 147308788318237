/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
// Imports
import Advertising from '../Advertising/Advertising';
import ImageMatrix from '../ImageMatrix/ImageMatrix';

import style from './CampaingTemplate.module.scss';
import BrandsCarousel from '../BrandsCarousel/BrandsCarousel';
import SliderFeaturedProductsInsider from '../SliderFeaturedProductsInsider/SliderFeaturedProductsInsider';
import CategoriesMenu from '../CategoriesMenu/CategoriesMenu';
import FullWidthInfoHome from '../FullWidthInfoHome/FullWidthInfoHome';
import Typography from '../../atoms/Typography/Typography';

const CampaingTemplate = ({
  components,
  handleImageMatrixClick,
  sliderHandleFeaturedClick,
}) => {
  const { state, Background } = components;

  if (!state) {
    return null;
  }

  return (
    <div
      style={{ backgroundColor: Background.color }}
      className={style.CampaingTemplate}
    >
      <Advertising
        state={components.firstAdvertisingCampaing?.state}
        name={components.firstAdvertisingCampaing?.name}
        imageUrlDesktop={components.firstAdvertisingCampaing?.imageUrlDesktop}
        imageUrlMobile={components.firstAdvertisingCampaing?.imageUrlMobile}
        url={components.firstAdvertisingCampaing?.url}
        ext={components.firstAdvertisingCampaing?.ext}
      />

      <FullWidthInfoHome
        backgroundColor={components?.fullWidthInfoHome?.backgroundColor}
        banner1={components?.fullWidthInfoHome?.banner1}
        banner2={components?.fullWidthInfoHome?.banner2}
        direction={components?.fullWidthInfoHome?.direction}
        imagesCarousel={components?.fullWidthInfoHome?.imagesCarousel}
        imagesCarouselB={components?.fullWidthInfoHome?.imagesCarouselB}
        state={components?.fullWidthInfoHome?.state}
        variant={components?.fullWidthInfoHome?.variant}
      />

      <ImageMatrix
        withOutDisplay
        state={components?.ImageMatrix?.state}
        content={components?.ImageMatrix?.content}
        onClick={handleImageMatrixClick}
        styles={components?.ImageMatrix?.styles}
        title={components?.ImageMatrix?.title}
      />

      <ImageMatrix
        withOutDisplay
        state={components?.secondImageMatrix?.state}
        content={components?.secondImageMatrix?.content}
        onClick={handleImageMatrixClick}
        styles={components?.secondImageMatrix?.styles}
        title={components?.secondImageMatrix?.title}
      />

      <BrandsCarousel
        state={components?.BrandsCarrusel?.state}
        carouselRows={components?.BrandsCarrusel?.carouselRows}
        arrowsPosition={components?.BrandsCarrusel?.arrowsPosition}
        title={components?.BrandsCarrusel?.title}
        brands={components?.BrandsCarrusel?.brands}
        currentCategory={components?.BrandsCarrusel?.category}
        // mainCategory={mainCategory}
      />

      {/* <SliderFeaturedProductsInsider
        onClick={sliderHandleFeaturedClick}
        products={components?.sliderFeaturedProductsInsider.products}
        state={components?.sliderFeaturedProductsInsider.state}
        title={components?.sliderFeaturedProductsInsider.title}
        variant={components?.sliderFeaturedProductsInsider.variant}
      /> */}

      <CategoriesMenu
        state={components?.categorias?.state}
        carouselRows={components?.categorias?.carouselRows}
        titlePosition={components?.categorias?.titlePosition}
        title={components?.categorias?.title}
        subtitle={components?.categorias?.subtitle}
        categories={components?.categorias?.data}
      />

      <Advertising
        state={components.secondAdvertisingCampaing?.state}
        name={components.secondAdvertisingCampaing?.name}
        imageUrlDesktop={components.secondAdvertisingCampaing?.imageUrlDesktop}
        imageUrlMobile={components.secondAdvertisingCampaing?.imageUrlMobile}
        url={components.secondAdvertisingCampaing?.url}
        ext={components.secondAdvertisingCampaing?.ext}
      />

      <ImageMatrix
        withOutDisplay
        state={components?.thirdImageMatrix?.state}
        content={components?.thirdImageMatrix?.content}
        onClick={handleImageMatrixClick}
        styles={components?.thirdImageMatrix?.styles}
        title={components?.thirdImageMatrix?.title}
      />

      <ImageMatrix
        withOutDisplay
        state={components?.fourthImageMatrix?.state}
        content={components?.fourthImageMatrix?.content}
        onClick={handleImageMatrixClick}
        styles={components?.thirdImageMatrix?.styles}
        title={components?.fourthImageMatrix?.title}
      />

      {/* <SliderFeaturedProductsInsider
        state={components?.secondSliderFeaturedProductsInsider.state}
        onClick={sliderHandleFeaturedClick}
        products={components?.secondSliderFeaturedProductsInsider.products}
        title={components?.secondSliderFeaturedProductsInsider.title}
        variant={components?.secondSliderFeaturedProductsInsider.variant}
      /> */}

      <CategoriesMenu
        state={components?.secondCategorias?.state}
        carouselRows={components?.secondCategorias?.carouselRows}
        titlePosition={components?.secondCategorias?.titlePosition}
        title={components?.secondCategorias?.title}
        subtitle={components?.secondCategorias?.subtitle}
        categories={components?.secondCategorias?.data}
      />
      {components?.terminosyCondiciones?.activo === 'true' && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant={components?.terminosyCondiciones?.variant}
            style={{
              color: components?.terminosyCondiciones?.color,
              textAlign: 'center',
              width: '70%',
              marginBottom: '2rem',
            }}
          >
            {components?.terminosyCondiciones?.value}
          </Typography>
        </div>
      )}
    </div>
  );
};

CampaingTemplate.propTypes = {
  components: PropTypes.shape({
    state: PropTypes.bool,
    Background: PropTypes.shape({}),
    terminosyCondiciones: PropTypes.shape({}),
  }),
};

export default CampaingTemplate;

/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
import { createSlice } from '@reduxjs/toolkit';

// const variantProduct = (product) => {
//   console.log(product?.variants);
//   console.log(product);
//   if (!product?.variants) return product;
//   if (product?.variants.length === 0) return product;
//   return product?.variants?.find(({ id }) => id === product.id);
// };

// const sizeOptions = (product) => product?.variants?.map((variant) => {
//   const attributeWithVariantAndSize = variant?.attributes?.find(
//     (attribute) => attribute.group === 'Variant' && attribute.name === 'size',
//   );
//   const value = attributeWithVariantAndSize?.values[0];
//   return value && { id: variant.id, value };
// });

const sizeOptions = (product) => {
  if (product.variants.length > 1) {
    const options = product.variants.map((variant) => {
      const {
        id, name, price, attributes,
      } = variant;

      const attributeWithVariantAndSize = attributes?.find(
        (attribute) => attribute.group === 'Variant' && attribute.name === 'size',
      );
      console.log(variant.name);

      const regexSetUnid = /Set [Xx] (\d+)/;
      const regexSetName = /(Set [Xx] \d+)/;

      const coincidenciaUnid = name.match(regexSetUnid);
      const coincidenciaUnidAttribute = attributeWithVariantAndSize?.values[0]?.match(regexSetUnid);
      const coincidenciaName = name.match(regexSetName);
      const coincidenciaNameAttribute = attributeWithVariantAndSize?.values[0]?.match(regexSetName);

      let Name;
      let unid;

      if (coincidenciaNameAttribute || coincidenciaName) {
        // eslint-disable-next-line prefer-destructuring
        Name = coincidenciaNameAttribute?.[0] || coincidenciaName?.[0];
        // eslint-disable-next-line prefer-destructuring
        unid = parseFloat(coincidenciaUnidAttribute?.[1]) || parseFloat(coincidenciaUnid?.[1]);
      } else {
        Name = 'Unidad';
        unid = 1;
      }

      const priceInt = parseFloat(price[0]?.price.replace(/\./g, ''));
      const precioPorUnidad = priceInt / unid;
      const precioPorUnidadRedondeado = Math.round(precioPorUnidad);

      const priceString = String(precioPorUnidadRedondeado);
      const totalPrice = priceString.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

      return {
        id,
        price: price[0]?.price,
        nameItem: Name,
        priceUnid: totalPrice,
        units: unid,
      };
    });
    return options;
  }
  return [];
};

const colorOptions = (product) => product?.variants?.map((variant) => {
  const attributeWithVariantAndColor = variant?.attributes?.find(
    (attribute) => attribute.group === 'Variant' && attribute.name === 'primary_color_hex',
  );
  const value = attributeWithVariantAndColor?.values[0];
  return value && { id: variant.id, backgroundColor: value };
});

const services = (product) => product?.serviceDetails;
const warranties = (product) => product?.warrantyDetails;
const getDefaultParentCategoryId = (product) => product?.defaultParentCategoryId;

const getDataSheetBox = (product) => product.attributes.filter(({ group }) => group === 'AttributesSortedMap');

const productDetailsSlice = createSlice({
  name: 'productDetailsState',
  initialState: {
    variantProduct: {},
    allVariantsProduct: [],
    selectorSizeOptions: [],
    selectorColorOptions: [],
    selectedIdVariant: [],
    services: [],
    warranties: [],
    DataSheetBox: [],
    defaultParentCategoryId: '',
  },
  reducers: {
    setVariantProduct: (state, action) => {
      state.variantProduct = action.payload;
    },
    setSelectorSizeOptions: (state, action) => {
      state.selectorSizeOptions = sizeOptions(action.payload);
    },
    setSelectorColorOptions: (state, action) => {
      state.selectorColorOptions = colorOptions(action.payload);
    },
    setSelectedIdVariant: (state, action) => {
      state.selectedIdVariant = action.payload;
    },
    setAllVariantsProduct: (state, action) => {
      state.allVariantsProduct = action.payload;
    },
    setServices: (state, action) => {
      state.services = services(action.payload);
    },
    setWarranties: (state, action) => {
      state.warranties = warranties(action.payload);
    },
    setDataSheetBox: (state, action) => {
      state.DataSheetBox = getDataSheetBox(action.payload);
    },
    setDefaultParentCategoryId: (state, action) => {
      state.defaultParentCategoryId = getDefaultParentCategoryId(
        action.payload,
      );
    },
  },
});

export const {
  setVariantProduct,
  setSelectorSizeOptions,
  setSelectorColorOptions,
  setSelectedIdVariant,
  setServices,
  setWarranties,
  setDataSheetBox,
  setDefaultParentCategoryId,
  setAllVariantsProduct,
} = productDetailsSlice.actions;
export default productDetailsSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import style from './ProductPrice.module.scss';

const ProductPrice = ({ content, caja, ...props }) => (
  <p
    className={classNames(style.productPrice, props.className)}
  >
    {`$ ${content} ${caja}`}
  </p>
);

ProductPrice.propTypes = {
  content: PropTypes.string,
  caja: PropTypes.string,
  className: PropTypes.string,
};

ProductPrice.defaultProps = {
  content: '0.000',
};

export default ProductPrice;

import React from 'react';
import PropTypes from 'prop-types';
import style from './ProductNormalPrice.module.scss';

const ProductNormalPrice = ({ content, unit }) => {
  if (unit === 'm2') {
    unit = 'm2';
  } else {
    unit = 'UND';
  }

  return (
    <p className={style.productNormalPrice}>
      Normal:
      <br />
      {`$${content} ${unit}`}
    </p>
  );
};

ProductNormalPrice.propTypes = {
  content: PropTypes.string,
  unit: PropTypes.string,
};

ProductNormalPrice.defaultProps = {
  content: '0.000',
  unit: 'UND',
};

export default ProductNormalPrice;

/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import Carousel from 'react-simply-carousel';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import SectionTitle from '../../atoms/SectionTitle/SectionTitle';
import CardFilterPLP from '../../atoms/CardFilterPLP/CardFilterPLP';
import styles from './FilterGeneralPLP.module.scss';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight2.svg';
import { reorderData } from '../../../../utils';

const FilterGeneralPLP = ({
  state,
  defaultItem,
  data: catData,
  onClick,
  sectionTitle,
  selectedFilter,
  renderLogo,
  background,
}) => {
  const [storeItems, setStoreItems] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeState, setActiveState] = useState(selectedFilter);

  useEffect(() => {
    setStoreItems(catData);
  }, [catData]);

  useEffect(() => {
    // Condición para mover el carrusel
    if (activeSlide > 4) {
      setActiveSlide(3);
    } else {
      setActiveSlide(activeSlide);
    }
  }, [activeSlide]);

  const handleClick = (value, color, cta) => {
    onClick(value, color, cta);
    setActiveState({
      selection: cta,
      color,
    });
  };

  const IsMobile = useMediaQuery({
    query: '(max-width: 1280px)',
  });

  const showLineaInicio = IsMobile
    ? true
    : activeSlide !== 0;

  const renderCarrousel = () => (
    <>
      {showLineaInicio && (
      <div
        style={{
          background: `linear-gradient(90deg, ${background} 0%, rgba(255, 255, 255, 0) 100%)`,
        }}
        className={styles.linea__inicio}
      />
      )}

      <Carousel
        centerMode={IsMobile}
        infinite={IsMobile}
        updateOnItemClick
        activeSlideIndex={activeSlide}
        onRequestChange={setActiveSlide}
        itemsToShow={2}
        itemsToScroll={1}
        showArrows
        responsiveProps={
          [
            {
              itemsToShow: 3,
              itemsToScroll: 1,
              minWidth: 420,
            },
            {
              itemsToShow: 4,
              itemsToScroll: 1,
              minWidth: 560,
            },
            {
              itemsToShow: 5,
              itemsToScroll: 1,
              minWidth: 690,
            },
            {
              itemsToShow: 6,
              itemsToScroll: 1,
              minWidth: 820,
            },
            {
              itemsToShow: 7,
              itemsToScroll: 2,
              minWidth: 1920,
            },
            {
              itemsToShow: 8,
              itemsToScroll: 1,
              minWidth: 1920,
            },
            {
              itemsToShow: 9,
              itemsToScroll: 3,
              minWidth: 1920,
            },
          ]
          }
        forwardBtnProps={{
          children: <ArrowRight />,
          className: 'nextButtonF',
        }}
        backwardBtnProps={{
          children: <ArrowLeft />,
          className: 'prevButtonF',
        }}
        easing="linear"
        speed={400}
      >
        {storeItems.map(({ ...props }, index) => (
          <div className={styles.cardFilter__container} key={index}>
            <CardFilterPLP
              defaultItem={storeItems[0]?.cta}
              selection={activeState.selection}
              onClick={handleClick}
              type="big"
              {...props}
            />
          </div>
        ))}
      </Carousel>
      <div
        style={{
          background: `linear-gradient( 270deg, ${background} 0%, rgba(255, 255, 255, 0) 100%)`,
        }}
        className={styles.linea}
      />
    </>

  );

  if (!state) {
    return null;
  }

  if (catData.length > 6) {
    return (
      <nav role="navigation" className={styles.categoriesFilter}>
        <SectionTitle text={sectionTitle} />
        <div className={`${styles.categoriesFilter__general} big`}>
          {renderLogo()}
          <div className={styles.categoriesFilter__carrusel}>
            {renderCarrousel()}
          </div>
          <div
            className={styles.categoriesFilter__line}
            style={{ backgroundColor: `${activeState?.color?.selected}` }}
          />
        </div>
      </nav>
    );
  }

  return (
    <nav role="navigation" className={styles.categoriesFilter}>
      <SectionTitle text={sectionTitle} />
      <div className={styles.categoriesFilter__general}>
        {renderLogo()}
        <div className={styles.categoriesFilter__container}>
          {storeItems.map(({ ...props }, index) => (
            <div className={styles.cardFilter__container} key={index}>
              <CardFilterPLP
                selection={activeState.selection}
                onClick={handleClick}
                type="small"
                {...props}
              />
            </div>
          ))}
        </div>
        <div
          className={styles.categoriesFilter__line}
          style={{ backgroundColor: activeState?.color?.selected }}
        />
      </div>
    </nav>
  );
};
FilterGeneralPLP.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Default item
   */
  defaultItem: PropTypes.number,
  /**
   * Default click
   */
  onClick: PropTypes.func,
  /**
   * Data to populate
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  renderLogo: PropTypes.func,
  sectionTitle: PropTypes.string,
  background: PropTypes.string,
  selectedFilter: PropTypes.shape({
    selection: PropTypes.string,
    color: PropTypes.string,
  }),
};

export default FilterGeneralPLP;

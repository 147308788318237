import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import PLPtemplate from '../../components/UI/templates/PLPtemplate/PLPtemplate';
import ProductComparisonSelect from '../../components/UI/organisms/ProductComparisonSelect/ProductComparisonSelect';
import ModalPlp from '../../components/UI/organisms/ModalPlp/ModalPlp';

import {
  deleteHeadData,
  deleteProductComparison, resetStates, setShowAlert, setShowProductComparison,
} from '../../redux/slices/PLP/productComparisonSlice';
import ProductComparison from '../../components/UI/organisms/ProductComparison/ProductComparison';
import AlertCartModalPlp from '../../components/UI/organisms/AlertCartModalPlp/AlertCartModalPlp';
import {
  setAddExtraToCart, setIsPDP, setLabelsModalPlp, setOpenModalPlp, setRedirectToCart,
} from '../../redux/slices/PDP/alertModalPlpSlice';
import { setQuantity } from '../../redux/slices/Cart/cartSlice';
import GeolocalizationModal from '../../components/UI/organisms/GeolocalizationModal/GeolocalizationModal';
import { setOpenModal } from '../../redux/slices/PLP/geolocalizationSlice';

const ProductsListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    store, selectedFilter, mainCategory, whatsAppCta,
  } = useSelector(
    (state) => state.transversalSlice,
  );

  const whatssappButton = useMemo(() => document.querySelector('[title="WhatsApp"]'));

  const {
    productsComparison,
    showBoxComparison,
    showAlert,
    showProductComparison,
    headData,
    specificationsData,
  } = useSelector((state) => state.productComparison);

  const { isOpen: isOpenGeoModal } = useSelector((state) => state.geolocalization);

  const {
    productId: skuToAdd,
    isOpenModal,
    // response,
  } = useSelector((state) => state.alertModalPlp);

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => {
        dispatch(setShowAlert(false));
      }, 12000);
    }
    dispatch(setIsPDP(false));
  }, [showAlert]);
  useEffect(() => {
    if (whatssappButton) {
      const divs = whatssappButton.querySelectorAll('div');
      console.log(divs);
      divs.forEach((div) => {
        div.style.zIndex = '6';
      });
      const imgs = whatssappButton.querySelectorAll('img');
      imgs.forEach((img) => {
        img.style.zIndex = '6'; // Aplicar más estilos o atributos según sea necesario
      });
    }
  }, [whatssappButton]);

  useEffect(() => {
    if (whatssappButton) {
      whatssappButton.setAttribute('href', `${whatsAppCta}`);
    }
  }, [whatssappButton]);

  const handleOnCloseCard = (sku) => {
    dispatch(deleteProductComparison({ sku }));
    dispatch(deleteHeadData({ sku }));
  };

  const handleAllClean = () => dispatch(resetStates());
  const handleCompare = () => {
    dispatch(setShowProductComparison(true));
  };
  const handleCloseProductComparison = () => {
    dispatch(setShowProductComparison(false));
  };

  return (
    <>
      <PLPtemplate
        store={store}
        mainCategoryId={mainCategory}
        selectedFilterProp={selectedFilter}
      />
      <ModalPlp state={showBoxComparison}>
        <ProductComparisonSelect
          buttonColor="secondary"
          dividerColor="#5EA1DB"
          products={productsComparison}
          showAlert={showAlert}
          onCloseClick={handleOnCloseCard}
          onAllClean={handleAllClean}
          onCompare={handleCompare}
        />
      </ModalPlp>
      <ProductComparison
        state={showProductComparison}
        buttonColor="secondary"
        dividerColor="#5EA1DB"
        onClose={handleCloseProductComparison}
        onCleanAll={handleAllClean}
        onCloseProduct={handleOnCloseCard}
        onProductClick={(productId) => navigate(`/product/${productId}`)}
        headData={headData}
        rowsData={specificationsData}
      />

      {isOpenModal && (
        <AlertCartModalPlp
          showProductInfo={!!skuToAdd}
          // response={response}
          productId={skuToAdd}
          isOpen={isOpenModal}
          onClose={() => {
            dispatch(
              setOpenModalPlp({
                productId: '',
                isOpen: false,
                isError: false,
              }),
            );
            dispatch(
              setLabelsModalPlp({
                alertLabels: {},
                alertIcon: '',
              }),
            );
            dispatch(setRedirectToCart({ redirect: false }));
            dispatch(setAddExtraToCart({ addExtraToCart: false }));
            dispatch(setQuantity(1));
          }}
        />
      )}
      {isOpenGeoModal && (
      <GeolocalizationModal
        productId
        isOpen
        onClose={() => {
          dispatch(
            setOpenModal({
              isOpen: false,
            }),
          );
        }}
      />
      )}
    </>
  );
};

ProductsListPage.propTypes = {};

export default ProductsListPage;

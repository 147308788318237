/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import styled, { css } from 'styled-components';

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  height: 42px;
  width: 100%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }
  & > span {
    margin: 0 6px;
  }

  ${(props) => {
    if (props.variant === 'contained') {
      if (props.color === 'primary') {
        if (props.isPDP) {
          return css`
            color: ${props.theme.pdp.button.text};
            & > svg path {
              fill: ${props.theme.pdp.button.text};
            }
            background-color: ${props.theme.pdp.button.default};
            &:active {
              background-color: ${props.theme.pdp.button.selected};
            }
          `;
        }
        return css`
          color: ${props.theme.primary.text};
          & > svg path {
            fill: ${props.theme.primary.text};
          }
          background-color: ${props.theme.primary.primary};
          &:hover {
            color:white;
            background-color: ${props.theme.primary.shaded};
          }

        `;
      }
      if (props.color === 'secondary') {
        return css`
          color: ${props.theme.secondary.text};
          & > svg path {
            fill: ${props.theme.secondary.text};
          }
          background-color: ${props.theme.secondary.secondary};
          &:hover {
            background-color: ${props.theme.secondary.shaded};
          }
        `;
      }
    }
    if (props.variant === 'outlined') {
      if (props.color === 'primary') {
        return css`
          background-color: transparent;
          border: 1px solid ${props.theme.primary.primary};
          color: ${props.theme.primary.primary};
          & > svg path {
            fill: ${props.theme.primary.primary};
          }
          &:hover {
            background-color: ${props.theme.primary.primary};
            color: ${props.theme.primary.text};
            & > svg path {
              fill: ${props.theme.primary.text};
            }
          }
          &:active {
            background-color: ${props.theme.primary.dark};
            color: ${props.theme.primary.text};
            & > svg path {
              fill: ${props.theme.primary.text};
            }
          }
        `;
      }
      if (props.color === 'secondary') {
        return css`
          background-color: transparent;
          border: 1px solid ${props.theme.secondary.secondary};
          color: ${props.theme.secondary.secondary};
          & > svg path {
            fill: ${props.theme.secondary.secondary};
          }
          &:hover {
            background-color: ${props.theme.secondary.secondary};
            color: ${props.theme.secondary.text};
            & > svg path {
              fill: ${props.theme.secondary.text};
            }
          }
          &:active {
            background-color: ${props.theme.secondary.dark};
            color: ${props.theme.secondary.text};
            & > svg path {
              fill: ${props.theme.secondary.text};
            }
          }
        `;
      }
    }
    if (props.variant === 'loading') {
      if (props.color === 'secondary') {
        if (props.isPDP) {
          return css`
            background-color: #f5f5f5;
            color: ${props.theme.pdp.button.default};
            svg {
              width: 38px;
              height: 38px;
              path {
                fill: ${props.theme.pdp.button.default} !important;
              }
            }
            span {
              display: flex;
              align-items: center;
            }
          `;
        }
        return css`
          background-color: #f5f5f5;
          color: ${props.theme.secondary.secondary};
          svg {
            width: 38px;
            height: 38px;
          }
          span {
            display: flex;
            align-items: center;
          }
        `;
      }
    }
    if (props.variant === 'text') {
      if (props.color === 'secondary') {
        // if (props.isPDP) {
        //   return css`
        //     background-color: #f5f5f5;
        //     color: ${props.theme.pdp.button.default};
        //     svg {
        //       width: 38px;
        //       height: 38px;
        //       path {
        //         fill: ${props.theme.pdp.button.default} !important;
        //       }
        //     }
        //     span {
        //       display: flex;
        //       align-items: center;
        //     }
        //   `;
        // }
        return css`
          background-color: transparent;
          color: ${props.theme.secondary.secondary};
          & > svg path {
            fill: ${props.theme.secondary.secondary};
          }
        `;
      }
    }
    return null;
  }}
`;

const Button = ({
  isDisabled, children, variant, ...props
}) => (
  <ButtonWrapper
    theme={props.theme}
    color={props.color}
    variant={variant}
    disabled={isDisabled}
    isPDP={props.isPDP}
    onClick={props.onClick}
  >
    {props.startIcon && <SVG src={props.startIcon} />}
    <span>{children}</span>
    {props.endIcon && <SVG src={props.endIcon} />}
  </ButtonWrapper>
);

Button.propTypes = {
  theme: PropTypes.shape(PropTypes.shape({})),
  /**
   * The content of the Button
   */
  children: PropTypes.node,
  /**
   * The varianto of the Button
   */
  variant: PropTypes.oneOf(['contained', 'outlined']),
  rounded: PropTypes.bool,
  isPDP: PropTypes.bool,
  isDisabled: PropTypes.bool,
  /**
   * Node placed before the children
   */
  startIcon: PropTypes.node,
  /**
   * Node placed after the children
   */
  endIcon: PropTypes.node,
  /**
   * The color of the button component
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'black', 'success']),
  /**
   * The call to action of the button component
   */
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Button.defaultProps = {
  rounded: false,
};

export default Button;

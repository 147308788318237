/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import SmallHeader from '../../organisms/SmallHeader/SmallHeader';
import ProductPrimaryInfo from '../../molecules/ProductPrimaryInfo/ProductPrimaryInfo';
import ProductImageBoxPDP from '../../molecules/ProductImageBoxPDP/ProductImageBoxPDP';
import DeliveryStock from '../../organisms/DeliveryStock/DeliveryStock';
import ProductDataSheetBoxPDP from '../../organisms/ProductDataSheetBoxPDP/ProductDataSheetBoxPDP';
import RelatedProductsPDP from '../../organisms/RelatedProductsPDP/RelatedProductsPDP';
import SimilarProductsPDP from '../../organisms/SimilarProductsPDP/SimilarProductsPDP';
import ReviewsTemplate from '../ReviewsTemplate/ReviewsTemplate';
import ProductSelectorBoxPDP from '../../molecules/ProductSelectorBoxPDP/ProductSelectorBoxPDP';
import ProductPriceBoxPDP from '../../molecules/ProductPriceBoxPDP/ProductPriceBoxPDP';
import ActionsMyProductList from '../../molecules/ActionsMyProductList/ActionsMyProductList';
import ProductSatisfactionBoxPDP from '../../molecules/ProductSatisfactionBoxPDP/ProductSatisfactionBoxPDP';
import Container from '../../../layout/Container/Container';
import SizeSelector from '../../molecules/SizeSelector/SizeSelector';
import ToggleButtonList from '../../molecules/ToggleButtonList/ToggleButtonList';
import ToggleButton from '../../atoms/ToggleButton/ToggleButton';
import styles from './PDPTemplate.module.scss';
import Modal from '../../organisms/Modal/Modal';
import { setIsOpenReviewModal } from '../../../../redux/slices/PDP/productReviewSlice';
import ReviewActions from '../../molecules/ReviewsActions/ReviewActions';
import ReviewActionsEmpty from '../../molecules/ReviewActionsEmpty/ReviewActionsEmpty';
import { ReactComponent as Xmark } from '../../../../assets/svg/Xmark.svg';
import IconButton from '../../atoms/IconButton/IconButton';
import usePrices from '../../../Hooks/usePrices/usePrices';
import useBadges from '../../../Hooks/useBadges/index';
import { setQuantity } from '../../../../redux/slices/Cart/cartSlice';
import ProductDescription from '../../organisms/ProductDescription/ProductDescription';
import ProductDeliveryModal from '../../organisms/ProductDeliveryModal/ProductDeliveryModal';
import FloorCalculator from '../../molecules/FloorCalculator/FloorCalculator';
import {
  setOpenDeliveryModal,
  setStockResult,
} from '../../../../redux/slices/PDP/deliverySlice';
import { useLazyGetSimilarProductDetailsQuery } from '../../../../redux/slices/PDP/insiderApi';
import {
  useLazyGetProductDetailsQuery,
  useLazyGetProductInventoryQuery,
  useLazyGetRelatedProductsQuery,
} from '../../../../redux/slices/PDP/productDetailsApi';
import {
  setSelectedIdVariant,
  setVariantProduct,
  setDataSheetBox,
} from '../../../../redux/slices/PDP/productDetailsSlice';
import {
  mapBodyToAddProduct,
  mapBodyToAddSeveralProducts,
  useAddCartLinesMutation,
  useAddToCartMutation,
  useLazyCheckCartQuantityQuery,
} from '../../../../redux/slices/Cart/checkoutApi';
import ColorPick from '../../atoms/ColorPick/ColorPick';
import { useLazyGetAllProductImagesQuery } from '../../../../redux/slices/PDP/productImagesApi';
import Loader from '../../atoms/Loader/Loader';
import NotAvailableProductBox from '../../molecules/NotAvailableProductBox/NotAvailableProductBox';
import style from '../../molecules/AlertCartModalBox/AlertCartModalBox.module.scss';
import Alert from '../../atoms/Alert/Alert';
import useIsVisible from '../../../Hooks/useIsVisible/useIsVisible';
import { setCartId } from '../../../../redux/slices/transversalSlice';
import {
  addToCardMarcation,
  trackPageView,
} from '../../../../utils/omnitureConection';
import ViewButtons from '../../molecules/ViewButtons/ViewButtons';
import { useGetProductDeliveryInfoQuery } from '../../../../redux/slices/PDP/productDeliveryApi';
import Typography from '../../atoms/Typography/Typography';

import PriceSet from '../../molecules/PriceSet/PriceSet';
import { CloudeFlare } from '../../../../constants';
import MetaTags from '../../atoms/MetaTags/MetaTags';

const PDPTemplate = ({
  // smallHeader,
  product,
  productId,
  productImages,
  warrantyData,
  similiarProductsSize,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addCartLines] = useAddCartLinesMutation();

  const [targetRef, isIntersecting] = useIsVisible({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });
  const [breadcrumbs, setBreadcrumbs] = useState(product?.breadcrumbs);
  const [hasStock, setHasStock] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [updateItem, setUpdateItem] = useState(false);

  const [isImageBroke, setIsImageBroke] = useState(false);

  const { isOpenReviewModal, reviews } = useSelector(
    (state) => state.productReview,
  );

  const {
    store,
    smallHeader,
    theme,
    departamento,
    ciudad: { name: cityName, id: cityId },
    cartId,
    location,
    barrio,
  } = useSelector((state) => state.transversalSlice);

  const { quantity } = useSelector((state) => state.cartSlice);

  const {
    openModalType, isOpenModal, stockListMapped, availabilities,
  } = useSelector((state) => state.delivery);

  const {
    variantProduct,
    selectorSizeOptions,
    selectorColorOptions,
    selectedIdVariant,
    services,
    warranties,
    DataSheetBox,
    allVariantsProduct,
  } = useSelector((state) => state.productDetailsReducer);

  const [allVariantsPrices, setAllVariantsPrices] = useState([]);
  useEffect(() => {
    console.log('allVariantsProduct', allVariantsProduct);
    const prices = allVariantsProduct.map((variant) => variant.price);
    setAllVariantsPrices(prices);
    console.log('allVariantsPrices', allVariantsPrices);
  }, [allVariantsProduct]);

  const [addToCart, { isLoading: isAddToCartLoading }] = useAddToCartMutation();

  const [getSimilarProductDetails, { data, isLoading }] = useLazyGetSimilarProductDetailsQuery();

  const [
    getImages,
    { data: images, isLoading: imagesAreLoading, error: imagesError },
  ] = useLazyGetAllProductImagesQuery();

  const [
    getRelatedProducts,
    { data: relatedData, isLoading: relatedIsLoading, error: errorIsLoading },
  ] = useLazyGetRelatedProductsQuery();

  const [
    getProductDetails,
    { data: productData, error: productError, isLoading: productIsLoading },
  ] = useLazyGetProductDetailsQuery();

  const [
    getInventory,
    { data: stockResult, isLoading: isLoadingStock, error: isErrorStock },
  ] = useLazyGetProductInventoryQuery(productId);

  const { data: deliveryData, error } = useGetProductDeliveryInfoQuery({
    priceGroup: location,
    region: departamento,
    municipal: barrio,
    deliveryMethod: 'homeDelivery',
    sku: variantProduct?.id,
  });

  const [getCartData, { data: cartData, error: cartDataError }] = useLazyCheckCartQuantityQuery({
    location,
    cartId,
  });

  const { data: deliveryStockInStore, errorStockInStore } = useGetProductDeliveryInfoQuery({
    priceGroup: location,
    region: departamento,
    municipal: barrio,
    deliveryMethod: 'storePickUp',
    sku: variantProduct?.id,
  });

  const validateHasStock = (dataProduct) => dataProduct?.availability?.some((it) => it.hasStock);

  const handleClick = (ev) => {
    dispatch(setQuantity(ev));
  };

  const handleAddToCartFromRelatedProducts = (arrayOfIds) => {
    const allProducts = [...arrayOfIds, productId];
    addToCart({
      cartId,
      body: mapBodyToAddSeveralProducts(allProducts),
      isPDP: true,
    });
  };

  const handleAddToCart = () => {
    getProductDetails({ productId, departamento, cityId }).then(
      ({ data: infoProduct }) => {
        addToCardMarcation({
          catId: infoProduct.result.defaultParentCategoryId,
          price: infoProduct.result.variants[0].price[0].price,
          productName: infoProduct.result.variants[0].name,
          sku: infoProduct.result.currentVariant,
        });
      },
    );

    addToCart({
      cartId,
      body: mapBodyToAddProduct(variantProduct?.id, quantity),
      isPDP: true,
    })
      .then(() => {
        getCartData({
          location,
          cartId,
        }).then((cart) => {
          if (cart?.error?.status === 404) {
            Cookies.remove('cartId', { path: '' });

            addCartLines(mapBodyToAddProduct(variantProduct?.id, 1)).then((res) => {
              const cardIdNew = res.data.data.cart.cartId;
              dispatch(setCartId(cardIdNew));
              Cookies.set('cartId', cardIdNew);

              addToCart({
                cardIdNew,
                body: mapBodyToAddProduct(variantProduct?.id, quantity),
                isPDP: true,
              });
            });
          }
          const buttonCartQuantity = document.querySelector(
            '.MiniCart-module__quantity___15GMp',
          );

          if (buttonCartQuantity && cartData.data) {
            buttonCartQuantity.textContent = cartData?.data?.cart?.totalProductsQuantityCount;
          }
        });
      })
      .catch((err) => console.log(err));
  };

  const handleAddReview = () => {
    if (global.$BV) {
      global.$BV.ui('rr', 'submit_review', { productId });
    }
  };

  const getCategoryList = (array) => {
    const newList = array?.map((it) => it.name);
    return newList;
  };

  const handleClickNavigate = (productIdNew) => {
    setUpdateItem(true);
    navigate(`/?store=${store}`);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      navigate(`/product/${productIdNew}`);
      setUpdateItem(false);
    }, 100);
  };

  const { badges } = useBadges(variantProduct, services, warranties);

  const {
    badgeImage,
    discount,
    saving,
    unitPrice,
    auxPrice,
    subAux,
    multiPurposeBadgeImage,
    regularPrice,
  } = usePrices(variantProduct, badges);

  const productPriceData = {
    badgeImage,
    discount,
    saving,
    unitPrice,
    auxPrice,
    subAux,
    regularPrice,
  };

  const [selectedVariantIndex, setSelectedVariantIndex] = useState(0);

  const handleVariants = (itemId) => {
    const filteredCurrentVariant = allVariantsProduct.find(
      ({ id }) => id === itemId,
    );
    const variantIndex = allVariantsProduct.findIndex(
      ({ id }) => id === itemId,
    );
    dispatch(setVariantProduct(filteredCurrentVariant));
    setSelectedVariantIndex(variantIndex);
  };

  const IsMobile = useMediaQuery({
    query: '(max-width: 792px)',
  });

  const [showButton, setShowButton] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    if (productId && similiarProductsSize) {
      getProductDetails({ productId, departamento, cityId });
      getSimilarProductDetails({
        productId,
        categoryList: getCategoryList(breadcrumbs),
        size: similiarProductsSize,
      });
    }

    const handleScroll = () => {
      if (divRef.current) {
        const rect = divRef.current.getBoundingClientRect();
        setShowButton(rect.top < 0 || rect.bottom > window.innerHeight);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleErrorImage = () => {
    setIsImageBroke(true);
  };

  const extractDiscount = (cadena) => {
    if (cadena) {
      const regex = /Ahorra\s(\d+\.\d+)%\sComprando\s(\d+)\s/;
      const match = cadena.match(regex);

      if (!match) {
        console.log('Formato de cadena incorrecto');
        return null;
      }

      const [_, descuentoStr, unidadesStr] = match;
      const descuento = parseFloat(descuentoStr);
      // eslint-disable-next-line radix
      const unidades = parseInt(unidadesStr);
      const item = [
        {
          id: variantProduct?.id,
          price: `${regularPrice.price}`,
          nameItem: 'Unidad',
          priceUnid: `${regularPrice.price}`,
          units: 1,
        },
      ];
      return {
        descuento,
        unidades,
        item,
        priceUnid: regularPrice.price,
      };
    }
    return null;
  };

  useEffect(() => {
    if (productData) {
      dispatch(setDataSheetBox(productData?.result));
      setBreadcrumbs(productData?.result?.breadcrumbs);
    }
  }, [productData]);

  useEffect(() => {
    if (product && variantProduct) {
      setHasStock(validateHasStock(variantProduct));
      // eslint-disable-next-line no-shadow
      const productId = variantProduct?.id;
      getRelatedProducts({ productId, departamento, cityId });
      getProductDetails({ productId, departamento, cityId });
      getInventory({ productId, departamento, cityId });
      getSimilarProductDetails({
        productId,
        categoryList: getCategoryList(breadcrumbs),
        size: similiarProductsSize,
      });
    }
  }, [variantProduct]);

  useEffect(() => {
    if (stockResult) {
      dispatch(setStockResult(stockResult));
    }
  }, [stockResult]);

  useEffect(() => {
    getImages({
      productId: variantProduct?.id,
      businessUnit: 'sodimac',
      country: 'CO',
    });
  }, [productId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    trackPageView({
      store,
      pageName: `pdp: ${breadcrumbs.slice(-2, -1)[0].name}`,
      pageType: 'product detail page',
      siteSection: `${breadcrumbs.slice(-2, -1)[0].name}`,
      subSection: `${breadcrumbs.slice(-2, -1)[0].name}`,

      catid: `${productData?.result?.defaultParentCategoryId}`,
      price: `${regularPrice.price}`,
      productName: `${variantProduct?.name}`,
      sku: `${productData?.result?.id}`,
    });
  }, [productData?.result?.id, productData?.result?.defaultParentCategoryId]);

  if (
    isLoading
    || imagesAreLoading
    || relatedIsLoading
    || productIsLoading
    || updateItem
  ) {
    return (
      <div className={style.loader}>
        <Loader />
      </div>
    );
  }

  const validateExtract = extractDiscount(multiPurposeBadgeImage?.name)
    && store === 'grandesvolumenes';
  const resultado = extractDiscount(multiPurposeBadgeImage.name);

  return (
    <div className={styles.container}>
      <MetaTags
        store={store}
        restUrl={`product/${productId}`}
        description={`${store} plp page`}
      />

      {!hasStock && !isIntersecting && (
        <NotAvailableProductBox
          badges={badges}
          branch={product?.brandName}
          error="Producto sin stock disponible"
          img={variantProduct?.id}
          name={variantProduct?.name}
          price={regularPrice.price}
          isScrolled
        />
      )}

      <ProductDeliveryModal
        type={openModalType}
        isOpen={isOpenModal}
        stockList={stockListMapped || stockResult}
        deliveryData={deliveryData}
        deliveryStock={deliveryStockInStore}
        onClose={() => dispatch(setOpenDeliveryModal({ type: '', isOpen: false }))}
      />

      <Modal
        className={styles.modalReview}
        isOpen={isOpenReviewModal}
        closeModal={() => dispatch(setIsOpenReviewModal(false))}
      >
        <div className={styles.modalReview__header}>
          <span className={styles.modalReview__divide} />
          <IconButton
            className={styles.modalReview__xmark}
            icon={<Xmark />}
            onClick={() => dispatch(setIsOpenReviewModal(false))}
            color="rgb(196, 196, 196)"
            size="small"
          />
        </div>
        {reviews && (
          <ReviewsTemplate {...reviews} handleAddReview={handleAddReview} />
        )}
      </Modal>
      <SmallHeader
        state={smallHeader.state}
        name={smallHeader.name}
        logo={smallHeader.logo}
        background={smallHeader.background}
        align={smallHeader.align}
        nameStore={store}
        breadcrumbs={breadcrumbs}
      />

      <Container fullScreenMobile fullScreenTablet>
        {!hasStock && (
          <section ref={targetRef}>
            <NotAvailableProductBox
              badges={badges}
              branch={product?.brandName}
              error="Producto sin stock disponible"
              img={variantProduct?.id}
              name={variantProduct?.name}
              price={regularPrice.price}
              isScrolled={isScrolled}
            />
          </section>
        )}
        <div className={styles.top}>
          <section className={styles.mediaInfo}>
            <div className={styles.stickyDiv}>
              {!hasStock && (
                <section
                  style={{
                    backgroundColor: '#fff',
                    paddingTop: '15px',
                  }}
                >
                  <Alert className={styles.notStockAlert}>
                    Producto sin stock disponible
                  </Alert>
                </section>
              )}

              {IsMobile && (
                <ProductPrimaryInfo
                  displayName={variantProduct?.name}
                  sku={variantProduct?.id}
                  brand={product?.brandName}
                  rating={reviews?.ratingAverage}
                  totalReviews={reviews?.totalReviews}
                />
              )}

              <ProductImageBoxPDP
                badges={badges.filter((item) => !Array.isArray(item))}
                productId={variantProduct.id}
                productImages={productImages}
              />

              <ViewButtons
                productId={productId}
                price={`${regularPrice.price} ${regularPrice.unit}`}
              />

              {!IsMobile && (
                <DeliveryStock
                  theme={theme}
                  storesQuantity={availabilities}
                  onClickSeeStores={() => dispatch(
                    setOpenDeliveryModal({
                      type: 'AVAILABILITY_OTHER_STORES',
                      isOpen: true,
                    }),
                  )}
                  onClickPickupInformation={() => dispatch(
                    setOpenDeliveryModal({
                      type: 'PICKUP_INFORMATION_IN_STORE',
                      isOpen: true,
                    }),
                  )}
                  onClickDeliveryInformation={() => dispatch(
                    setOpenDeliveryModal({
                      type: 'HOME_DELIVERY_INFORMATION',
                      isOpen: true,
                    }),
                  )}
                  onClickDeliveryExpressInformation={() => dispatch(
                    setOpenDeliveryModal({
                      type: 'HOME_DELIVERY_ESPRESS_INFORMATION',
                      isOpen: true,
                    }),
                  )}
                />
              )}
            </div>
          </section>
          <section className={styles.productInfo}>
            <div className={styles.productInfo__top}>
              {!IsMobile && (
                <ProductPrimaryInfo
                  displayName={variantProduct?.name}
                  sku={variantProduct?.id}
                  brand={product?.brandName}
                  rating={reviews?.ratingAverage}
                  totalReviews={reviews?.totalReviews}
                  variant="h1"
                />
              )}

              <ProductPriceBoxPDP
                price={`${regularPrice.symbol} ${regularPrice.price} ${regularPrice.unit}`}
                badgeImage={badgeImage}
                discount={discount}
                priceType={
                  Object.keys(auxPrice).length > 0
                    ? `${auxPrice.symbol} ${auxPrice.price}
                    ${auxPrice.unit}`
                    : null
                }
                savedTitle={saving}
                subAux={subAux}
                unitPrice={
                  unitPrice
                    ? `${unitPrice.symbol}
                    ${unitPrice.price}
                    ${unitPrice.unit}`
                    : null
                }
                multiPurposeBadgeImage={multiPurposeBadgeImage}
                ProductDescription={product?.longDescription}
                productName={variantProduct?.name}
                productUnitId={productData?.result?.id}
                productUnitPrice={productData?.result?.variants[0]?.price}
                productPrices={allVariantsPrices}
                selectedVariantIndex={selectedVariantIndex}
              />

              {store === 'constructor' && (
                <FloorCalculator displayName={variantProduct?.name} />
              )}

              {hasStock && (
                <>
                  <SizeSelector
                    state={
                      validateExtract
                      || !!selectorSizeOptions[0]
                      || !!selectorColorOptions[0]
                    }
                    title={
                      selectorSizeOptions[0] || validateExtract
                        ? 'Otras opciones'
                        : 'Selecciona tu color'
                    }
                  >
                    {selectorSizeOptions[0] && store === 'grandesvolumenes' && (
                      <PriceSet
                        options={selectorSizeOptions}
                        sku={variantProduct?.id}
                        onClick={handleVariants}
                      />
                    )}

                    {validateExtract && (
                      <PriceSet
                        options={resultado?.item}
                        sku={variantProduct?.id}
                        onClick={handleVariants}
                        multiPurposeBadgeImage={extractDiscount(
                          multiPurposeBadgeImage?.name,
                        )}
                      />
                    )}

                    {selectorColorOptions[0] && (
                      <ToggleButtonList
                        onHandleChange={(item) => handleVariants(item?.id)}
                        items={selectorColorOptions}
                        initialIndex={() => selectorColorOptions.findIndex(
                          (item) => item.id === variantProduct?.id,
                        )}
                        renderItem={(
                          item,
                          isHighlighted,
                          index,
                          onChangeSelectedIndex,
                        ) => (
                          <ColorPick
                            key={item.id}
                            isHighlighted={isHighlighted}
                            onClick={() => {
                              onChangeSelectedIndex(index, item);
                            }}
                            backgroundColor={`#${item?.backgroundColor}`}
                          />
                        )}
                      />
                    )}
                  </SizeSelector>

                  <div ref={divRef}>
                    <ProductSelectorBoxPDP
                      onClick={handleClick}
                      onClickAdd={handleAddToCart}
                      isAdding={isAddToCartLoading}
                      theme={theme}
                    />
                    <ActionsMyProductList
                      theme={theme}
                      onClickSaveMyLists={() => {}}
                      onClickSeeMyLists={() => {}}
                    />
                  </div>
                </>
              )}
            </div>

            {IsMobile && (
              <DeliveryStock
                theme={theme}
                storesQuantity={availabilities}
                onClickSeeStores={() => dispatch(
                  setOpenDeliveryModal({
                    type: 'AVAILABILITY_OTHER_STORES',
                    isOpen: true,
                  }),
                )}
                onClickPickupInformation={() => dispatch(
                  setOpenDeliveryModal({
                    type: 'PICKUP_INFORMATION_IN_STORE',
                    isOpen: true,
                  }),
                )}
                onClickDeliveryInformation={() => dispatch(
                  setOpenDeliveryModal({
                    type: 'HOME_DELIVERY_INFORMATION',
                    isOpen: true,
                  }),
                )}
                onClickDeliveryExpressInformation={() => dispatch(
                  setOpenDeliveryModal({
                    type: 'HOME_DELIVERY_ESPRESS_INFORMATION',
                    isOpen: true,
                  }),
                )}
              />
            )}

            <div className={styles.productInfo__bottom}>
              {DataSheetBox && <ProductDataSheetBoxPDP data={DataSheetBox} />}
              <ProductSatisfactionBoxPDP
                label={warrantyData?.button.label}
                text={warrantyData?.information.text}
                title={warrantyData?.information.title}
                url={warrantyData?.button.url}
              />
            </div>
          </section>
        </div>
      </Container>

      <Container fullScreenMobile fullScreenTablet>
        <div className={styles.bottom}>
          {relatedData?.result?.collectionProducts?.length > 1 && (
            <RelatedProductsPDP
              label="Agregar al carro"
              productPriceData={productPriceData}
              productId={variantProduct?.id}
              products={relatedData?.result?.collectionProducts}
              title="Cómpralo con:"
              product={product}
              similar={data}
              onClick={handleAddToCartFromRelatedProducts}
            />
          )}
          <ProductDescription
            state={!!product?.longDescription?.length}
            longDescription={product?.longDescription}
          />
          {data && (
            <SimilarProductsPDP
              products={data?.data}
              handleClickNavigate={handleClickNavigate}
              state
              title={{
                strong: false,
                value: 'Productos similares',
                variant: 'zeta20',
              }}
              variant="featured"
            />
          )}

          <MediaQuery minWidth={720}>
            {reviews && (
              <ReviewsTemplate {...reviews} handleAddReview={handleAddReview} />
            )}
          </MediaQuery>
          <MediaQuery maxWidth={719}>
            <ReviewActions
              state={reviews?.hasReviews}
              rating={reviews?.ratingAverage}
              totalReviews={reviews?.totalReviews}
              onClick={() => dispatch(setIsOpenReviewModal(true))}
            />
            <ReviewActionsEmpty
              state={!reviews?.hasReviews}
              rating={reviews?.ratingAverage}
              totalReviews={reviews?.totalReviews}
              onShowReviews={() => dispatch(setIsOpenReviewModal(true))}
              onWriteOpinion={() => {}}
            />
          </MediaQuery>
        </div>
      </Container>

      {showButton && (
        <>
          {/* Componente sticky */}
          <div
            className={`${styles.stickyComponent} ${
              showButton ? styles.stickyComponent__show : ''
            }`}
          >
            <div className={styles.stickyComponent__container}>
              <div className={styles.stickyComponent__firstComponent}>
                {isImageBroke ? (
                  <img
                    style={{ width: '100px', height: '100px' }}
                    src="https://images.contentstack.io/v3/assets/blt2f8082df109cfbfb/blt986a90bb4eedcd35/664769a80bbc6249927fa5a5/404.jpeg"
                    alt="Imagen de respaldo"
                  />
                ) : (
                  <img
                    style={{ width: '100px', height: '100px' }}
                    src={`${CloudeFlare}/${variantProduct.id}/thumbnail`}
                    alt="Imagen de producto"
                    onError={() => handleErrorImage()}
                  />
                )}
                <section style={{ display: 'flex', flexDirection: 'column' }}>
                  <ProductPrimaryInfo
                    displayName={variantProduct?.name}
                    sku={variantProduct?.id}
                    brand={product?.brandName}
                    rating={reviews?.ratingAverage}
                    totalReviews={reviews?.totalReviews}
                    variant="epsilon24"
                  />
                </section>
              </div>
              <div className={styles.stickyComponent__secondComponent}>
                <Typography
                  variant="epsilon24"
                  style={{ fontWeight: 'bold', marginRight: '20px' }}
                  className={styles.priceProductBox__price}
                >
                  {`${regularPrice.symbol} ${regularPrice.price} ${regularPrice.unit}`}
                </Typography>

                <ProductSelectorBoxPDP
                  onClick={handleClick}
                  onClickAdd={handleAddToCart}
                  isAdding={isAddToCartLoading}
                  theme={theme}
                />
              </div>
            </div>
          </div>
          {/* Componente sticky */}
        </>
      )}
    </div>
  );
};

PDPTemplate.propTypes = {
  data: PropTypes.shape({
    state: PropTypes.bool,
    name: PropTypes.string,
    logo: PropTypes.string,
    background: PropTypes.string,
    align: PropTypes.string,
  }),
  product: PropTypes.shape({}),
  productImages: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  ).isRequired,
  productId: PropTypes.string,
  similiarProductsSize: PropTypes.string,
  reviews: PropTypes.shape({}),
};

export default PDPTemplate;

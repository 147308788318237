/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-simply-carousel';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import CardFilter from '../../atoms/CardFilter/CardFilter';
import Breadcrumbs from '../../atoms/Breadcrumbs/Breadcrumbs';
import styles from './CategoriesMenuPLP.module.scss';
import Container from '../../../layout/Container/Container';
import { ReactComponent as ArrowLeft } from '../../../../assets/svg/ArrowLeft2.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/svg/ArrowRight2.svg';
import { ReactComponent as ArrowLeftWhite } from '../../../../assets/svg/ArrowLeft2White.svg';
import { ReactComponent as ArrowRightWhite } from '../../../../assets/svg/ArrowRight2White.svg';
import { isOverflown, isSVG, reorderData } from '../../../../utils';
import FilterGeneralPLP from '../FilterGeneralPLP/FilterGeneralPLP';
import useWindowSize from '../../../Hooks/useWindowSize/useWindowSize';

const CategoriesMenuPLP = ({
  state,
  defaultItem,
  onClick,
  breadcrumbsOnClick,
  filterOnClick,
  background,
  buttonsColor,
  logo,
  fontColor,
  arrowsColor,
  data: catData,
  dataParent,
  withFilter,
  dataFilter,
  withSubcategory,
  selectedFilter,
  selectedItem,
  nameStore,
  breadcrumbs,
  isMobile,
  mainCategory,
}) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeState, setActiveState] = useState({});
  const [activeStateFilter, setActiveStateFilter] = useState({});
  const ref = useRef(null);
  const [isWidthOverflown, setWidthIsOverflown] = useState(true);
  const windowSize = useWindowSize();

  const navigate = useNavigate();

  const { store } = useSelector((State) => State.transversalSlice);

  const validateUrl = (event) => {
    const stores = {
      constructor:
        'https://www.homecenter.com.co/homecenter-co/tiendas/constructor/?store=constructor',
      petcenter:
        'https://www.homecenter.com.co/homecenter-co/tiendas/tienda-mascotas-pet-center/?store=petcenter',
      carcenter:
        'https://www.homecenter.com.co/homecenter-co/tiendas/servicio-automotriz-carcenter/?store=carcenter',
      grandesvolumenes:
        'https://www.homecenter.com.co/homecenter-co/tiendas/compras-por-mayor-grandes-volumenes/?store=grandesvolumenes',
    };

    event.preventDefault();
    window.location.href = stores[nameStore];
  };

  useEffect(() => {
    setActiveState(selectedItem);
    setActiveStateFilter(selectedFilter);
  }, [selectedItem]);

  useEffect(() => {
    if (!withFilter) {
      setTimeout(() => {
        setWidthIsOverflown(isOverflown(ref.current));
      }, 1000);
    }
  }, [windowSize]);

  const handleClick = (value, color, cta, url, marcation) => {
    onClick(cta);
    const categoriesMenu = document.getElementById('categoriesMenu');
    categoriesMenu.scrollIntoView({ behavior: 'smooth', block: 'start' });

    setActiveState({
      selection: value,
      color,
    });

    const isCollection = url.startsWith('cat');

    if (url) {
      if (isCollection) {
        navigate(`/categories/${url}&${marcation || ''}`, { replace: true });
      } else {
        navigate(`/collection/${url}&${marcation || ''}`, { replace: true });
      }
    }
  };

  const handleClickFilter = (value, color, cta) => {
    filterOnClick(cta);
    setActiveStateFilter({
      selection: value,
      color,
    });
  };

  const renderLogo = (logoUrl) => (isSVG(logoUrl.logo) ? (
    <SVG src={logoUrl.logo} className={styles.logo__centrado} />
  ) : (
    <img
      alt={logoUrl.name}
      src={logoUrl.logo}
      className={styles.logo__centrado}
      style={{ cursor: 'pointer' }}
      onClick={validateUrl}
    />
  ));

  const renderProps = {
    nameStore,
    breadcrumbs,
    isMobile,
    name: logo?.name,
    mainCategory,
  };

  const styleBack = (bck) => ({
    background: `${bck.color ? bck.color : ''} url(${
      bck.img ? bck.img : ''
    }) top left/cover repeat-x`,
  });

  const renderCarrousel = (items) => (
    <Carousel
      activeSlideIndex={activeSlide}
      onRequestChange={setActiveSlide}
      infinite={false}
      responsiveProps={[
        {
          itemsToShow: 4,
          itemsToScroll: 1,
          minWidth: 700,
        },
        {
          itemsToShow: 5,
          itemsToScroll: 1,
          minWidth: 900,
        },
        {
          itemsToShow: 6,
          itemsToScroll: 1,
          minWidth: 900,
        },
        {
          itemsToShow: 7,
          itemsToScroll: 1,
          minWidth: 1160,
        },
        {
          itemsToShow: 8,
          itemsToScroll: 1,
          minWidth: 1320,
        },
      ]}
      forwardBtnProps={{
        children:
          arrowsColor === 'black' ? <ArrowRight /> : <ArrowRightWhite />,
        className: 'nextButton',
      }}
      backwardBtnProps={{
        children: arrowsColor === 'black' ? <ArrowLeft /> : <ArrowLeftWhite />,
        className: 'prevButton',
      }}
      itemsToShow={2}
      speed={300}
    >
      {catData?.map(({ id, ...rest }, index) => (
        <div
          style={{ margin: '100px' }}
          className={`${withSubcategory ? styles.subcategory : ''} ${
            styles.cardFilter__container
          }`}
          key={index}
        >
          <CardFilter
            PLP
            key={index}
            selection={activeState?.selection}
            selectionColor={activeState?.color}
            onClick={handleClick}
            fontColor={fontColor}
            withSubcategory={withSubcategory}
            {...rest}
            backgroundColor={buttonsColor}
            type="small"
          />
        </div>
      ))}
    </Carousel>
  );

  const justifiedContent = isWidthOverflown ? 'flex-start' : 'center';

  if (!state) {
    return null;
  }

  return (
    <>
      <Breadcrumbs {...renderProps} />
      <nav
        role="navigation"
        style={styleBack(background)}
        className={styles.categoriesFilter}
      >
        <Container fullScreenMobile>
          <div
            className={`${
              withFilter ? styles.categoriesFilterPLP_withFilter : ''
            } ${styles.categoriesFilterPLP_container}`}
          >
            {withFilter ? (
              <FilterGeneralPLP
                state
                background={background.color}
                data={dataFilter?.data}
                onClick={handleClickFilter}
                selectedFilter={selectedFilter}
                renderLogo={() => renderLogo(logo)}
              />
            ) : (
              renderLogo(logo)
            )}
            {withSubcategory && (
              <Breadcrumbs
                isSubcategory
                hasFilter={withFilter}
                image={dataParent}
                onClick={breadcrumbsOnClick}
              />
            )}
            <div
              className={`${withSubcategory ? styles.subcategory__gen : ''} ${
                styles.categoriesFilter__general
              }`}
            >
              <div
                className={`${
                  withSubcategory ? styles.categoriesFilter__container__sub : ''
                } ${styles.categoriesFilter__container}`}
                style={{ justifyContent: justifiedContent }}
                ref={ref}
              >
                {isMobile
                  ? catData?.map(({ id, ...rest }, index) => (
                    <div
                      className={`${styles.categoriesFilter__mobile}`}
                      key={index}
                    >
                      <CardFilter
                        PLP
                        key={index}
                        selection={activeState?.selection}
                        selectionColor={activeState?.color}
                        onClick={handleClick}
                        fontColor={fontColor}
                        withSubcategory={withSubcategory}
                        {...rest}
                        backgroundColor={buttonsColor}
                        type="small"
                      />
                    </div>
                  ))
                  : renderCarrousel(8)}
              </div>
            </div>
          </div>
        </Container>
      </nav>
    </>
  );
};
CategoriesMenuPLP.propTypes = {
  /**
   * State "true" to turn on component card and "false" to turn off component
   */
  state: PropTypes.bool,
  /**
   * Default item
   */
  defaultItem: PropTypes.number,
  /**
   * Default click
   */
  onClick: PropTypes.func,
  /**
   * Default Breadcrumbs click
   */
  breadcrumbsOnClick: PropTypes.func,
  /**
   * Default Filter click
   */
  filterOnClick: PropTypes.func,
  /**
   * Data to populate
   */
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  nameStore: PropTypes.string,
  mainCategory: PropTypes.string,
  selectedFilter: PropTypes.shape(),
  sectionTitle: PropTypes.string,
  background: PropTypes.shape({
    img: PropTypes.string,
    color: PropTypes.string,
  }),
  dataParent: PropTypes.shape({
    img: PropTypes.string,
    alt: PropTypes.string,
  }),
  buttonsColor: PropTypes.string,
  fontColor: PropTypes.string,
  logo: PropTypes.shape({
    img: PropTypes.string,
    alt: PropTypes.string,
    name: PropTypes.string,
  }),
  selectedItem: PropTypes.shape({
    selection: PropTypes.string,
    color: PropTypes.string,
  }),
  arrowsColor: PropTypes.oneOf(['white', ' black']),
  withFilter: PropTypes.bool.isRequired,
  dataFilter: PropTypes.shape({
    defaultItem: PropTypes.number,
    data: PropTypes.arrayOf({
      imgUrl: PropTypes.string,
      caption: PropTypes.string,
      backgroundColor: PropTypes.string,
      cta: PropTypes.string,
    }),
  }),
  withSubcategory: PropTypes.bool,
  isMobile: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};

export default CategoriesMenuPLP;

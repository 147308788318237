/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  setMainCategory,
  setSelectedFilter,
  setSmallHeader,
} from '../../../../redux/slices/transversalSlice';
import Advertising from '../../organisms/Advertising/Advertising';
import BrandsCarousel from '../../organisms/BrandsCarousel/BrandsCarousel';
import FullWidthInfoHome from '../../organisms/FullWidthInfoHome/FullWidthInfoHome';
import HomeHeader from '../../organisms/HomeHeader/HomeHeader';
import GeneralFilterHome from '../../organisms/GeneralFilterHome/GeneralFilterHome';
import CategoriesMenu from '../../organisms/CategoriesMenu/CategoriesMenu';
import FeaturedProductsSection from '../../organisms/FeaturedProductsSection/FeaturedProductsSection';
import LastBlogSection from '../../organisms/LastBlogSection/LastBlogSection';
import StoreDescription from '../../organisms/StoreDescription/StoreDescription';
import SmallHeader from '../../organisms/SmallHeader/SmallHeader';
import CalculatorSection from '../../organisms/CalculatorSection/CalculatorSection';
import SliderFeaturedProducts from '../../organisms/SliderFeaturedProducts/SliderFeaturedProducts';
import SectionGroup from '../../organisms/SectionGroup/SectionGroup';
import ImageMatrix from '../../organisms/ImageMatrix/ImageMatrix';
import InformativeCardsGroup from '../../organisms/InformativeCardsGroup/InformativeCardsGroup';
import TabsMaps from '../../organisms/TabsMaps/TabsMaps';
// import { useLazyGetSimilarProductDetailsHomeQuery } from '../../../../redux/slices/PDP/insiderApi';
import { useLazyGetBlobStorageCategoriesDataQuery } from '../../../../redux/slices/HOME/blobStorageApi';
import { hasHttp } from '../../../../utils';
import SliderFeaturedProductsInsider from '../../organisms/SliderFeaturedProductsInsider/SliderFeaturedProductsInsider';
import CampaingTemplate from '../../organisms/Campaing/CampaingTemplate';
import style from '../../molecules/AlertCartModalBox/AlertCartModalBox.module.scss';
import Loader from '../../atoms/Loader/Loader';
import { trackPageView } from '../../../../utils/omnitureConection';
import useLocalStorage from '../../../Hooks/useLocalStorage/useLocalStorage';
import LegalComponent from '../../organisms/LegalComponent/LegalComponent';
import FrequentQuestionsComponent from '../../organisms/FrequentQuestionsComponent/FrequentQuestionsComponent';
import MetaTags from '../../atoms/MetaTags/MetaTags';

const HomeTemplate = ({ initialSetUp: dataStore }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    store, campaing, selectedFilter, theme, mainCategory, whatsAppCta,
  } = useSelector((state) => state.transversalSlice);
  const [brands, setBrands] = useState({});
  const [advertising, setAdvertising] = useState([]);
  const [imageMatrix, setImageMatrix] = useState();
  const [categoriesSetup, setCategoriesSetup] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [initialSetUp, setInitialSetUp] = useLocalStorage('initialState', {});
  const whatssappButton = useMemo(() => document.querySelector('[title="WhatsApp"]'));

  const [defCta, setDefCta] = useState(
    dataStore?.generalFilterSetup?.defaultCategory.cta,
  );

  const [getBlobStorageCategoriesData, { data: categoriesData }] = useLazyGetBlobStorageCategoriesDataQuery();

  const handleClick = async (key, color) => {
    setDefCta(key);
    dispatch(
      setSelectedFilter({
        selection: key,
        color,
      }),
    );
    setBrands({
      setup: dataStore?.generalfilter[key]?.brandsCarrusel,
      brands: dataStore?.generalfilter[key]?.brandsCarrusel?.brands,
    });
    setAdvertising(dataStore?.generalfilter[key]?.advertising);
    setCategoriesSetup({
      setup: dataStore?.generalfilter[key]?.categorias,
    });
    setImageMatrix(dataStore?.generalfilter[key]?.imageMatrix);

    getBlobStorageCategoriesData({ store, key }).unwrap();
  };

  const handleFeaturedClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  const handleBannersClick = useCallback(
    (key) => {
      if (hasHttp(key)) {
        window.open(key, '_blank');
      } else {
        navigate(key);
      }
    },
    [store],
  );

  const handleCalculatorClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  const handleImageMatrixClick = (key) => {
    if (hasHttp(key)) {
      window.open(key, '_blank');
    } else {
      navigate(key);
    }
  };

  const getBackground = (color, image, rest) => ({
    backgroundImage: `url("${image}"), linear-gradient(180deg, ${color}99 0%, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 90%, ${color}99 100%)`,
    ...rest,
  });

  useEffect(() => {
    if (dataStore) {
      const ctaDef = dataStore?.generalFilterSetup?.defaultCategory.cta;
      setBrands({
        setup: dataStore?.generalfilter[ctaDef]?.brandsCarrusel,
        brands: dataStore?.generalfilter[ctaDef]?.brandsCarrusel?.brands,
      });
      setAdvertising(dataStore?.generalfilter[ctaDef]?.advertising);
      setImageMatrix(dataStore?.generalfilter[ctaDef]?.imageMatrix);
      setCategoriesSetup({
        setup: dataStore?.generalfilter[ctaDef]?.categorias,
      });
      setInitialSetUp({
        ...initialSetUp,
        dataStore,
      });
      dispatch(setMainCategory(dataStore?.mainCategoryId));
      dispatch(setSmallHeader(dataStore?.smallHeader));
      dispatch(
        setSelectedFilter({
          selection: dataStore?.generalFilterSetup?.defaultCategory?.cta,
          color: dataStore?.generalFilterSetup?.defaultCategory?.backgroundColor,
        }),
      );
    }
    getBlobStorageCategoriesData({ store, key: defCta }).unwrap();
  }, [dataStore]);

  useEffect(() => {
    if (whatssappButton && whatsAppCta !== '') {
      whatssappButton.setAttribute('href', `${whatsAppCta}`);
    }
  }, [whatssappButton]);

  useEffect(() => {
    if (whatssappButton) {
      const divs = whatssappButton.querySelectorAll('div');
      console.log(divs);
      divs.forEach((div) => {
        div.style.zIndex = '6';
      });
      const imgs = whatssappButton.querySelectorAll('img');
      imgs.forEach((img) => {
        img.style.zIndex = '6'; // Aplicar más estilos o atributos según sea necesario
      });
    }
  }, [whatssappButton]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  });

  useEffect(() => {
    trackPageView({ store, pageName: store, pageType: 'Content' });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  if (isLoading) {
    return (
      <div className={style.loader}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <MetaTags
        store={store}
        restUrl={`?store=${store}`}
        description=""
      />

      <SmallHeader
        state={dataStore?.smallHeader?.state}
        name={dataStore?.smallHeader?.name}
        logo={dataStore?.smallHeader?.logo}
        background={dataStore?.smallHeader?.background}
        align={dataStore?.smallHeader?.align}
        withoutBreadcrumbs
      />
      {/* Bienvenido Socio */}
      <HomeHeader
        state={dataStore?.homeHeader?.state}
        variant={dataStore?.homeHeader?.variant}
        direction={dataStore?.homeHeader?.direction}
        backgroundColor={dataStore?.homeHeader?.backgroundColor}
        imagesCarousel={dataStore?.homeHeader?.imagesCarousel}
        banner1={dataStore?.homeHeader?.banner1}
        banner2={dataStore?.homeHeader?.banner2}
        handleClick={handleBannersClick}
        theme={theme}
        cardClick={handleBannersClick}
      />
      {/* H1 */}
      <FullWidthInfoHome
        backgroundColor={dataStore?.fullWidthInfoHome?.backgroundColor}
        banner1={dataStore?.fullWidthInfoHome?.banner1}
        banner2={dataStore?.fullWidthInfoHome?.banner2}
        direction={dataStore?.fullWidthInfoHome?.direction}
        imagesCarousel={dataStore?.fullWidthInfoHome?.imagesCarousel}
        imagesCarouselB={dataStore?.fullWidthInfoHome?.imagesCarouselB}
        state={dataStore?.fullWidthInfoHome?.state}
        variant={dataStore?.fullWidthInfoHome?.variant}
        theme={theme}
      />
      {/* Calculadora - La manera más fácil de buscar lo que necesitas */}
      <CalculatorSection
        state={dataStore?.calculatorSection?.state}
        title={dataStore?.calculatorSection?.title}
        cards={dataStore?.calculatorSection?.cards}
        renderItems={dataStore?.calculatorSection?.renderItems}
        onClick={handleCalculatorClick}
        theme={theme}
      />
      <SliderFeaturedProductsInsider
        onClick={handleFeaturedClick}
        products={dataStore?.sliderFeaturedProductsInsider?.products}
        state={dataStore?.sliderFeaturedProductsInsider?.state}
        title={dataStore?.sliderFeaturedProductsInsider?.title}
        variant={dataStore?.sliderFeaturedProductsInsider?.variant}
      />
      <Advertising
        state={dataStore?.firstAdvertising?.state}
        name={dataStore?.firstAdvertising?.name}
        imageUrlDesktop={dataStore?.firstAdvertising?.imageUrlDesktop}
        imageUrlMobile={dataStore?.firstAdvertising?.imageUrlMobile}
        url={dataStore?.firstAdvertising?.url}
        ext={dataStore?.firstAdvertising?.ext}
      />
      <ImageMatrix
        withOutDisplay
        content={dataStore?.ImageMatrix?.content}
        onClick={handleImageMatrixClick}
        state={dataStore?.ImageMatrix?.state}
        title={dataStore?.ImageMatrix?.title}
        styles={dataStore?.ImageMatrix?.styles}
        variant={dataStore?.ImageMatrix?.variant}
      />

      <CampaingTemplate
        components={campaing}
        handleImageMatrixClick={handleImageMatrixClick}
        sliderHandleFeaturedClick={handleFeaturedClick}
      />

      {/* Especialidades  "Elija aquí su especialidad" */}
      <GeneralFilterHome
        shadowColors={dataStore?.generalFilterSetup?.shadowColors}
        state={dataStore?.generalFilterSetup?.state}
        size={dataStore?.generalFilterSetup?.type}
        defaultItem={dataStore?.generalFilterSetup?.defaultItem}
        data={dataStore?.generalFilterSetup?.data}
        onClick={handleClick}
        sectionTitle={dataStore?.generalFilterSetup?.sectionTitle}
        sectionSubtitle={dataStore?.generalFilterSetup?.sectionSubtitle}
        inlineStyles={dataStore?.generalFilterSetup?.styles}
        itemsToShow992={dataStore?.generalFilterSetup?.itemsToShow992}
        itemsToShow1400={dataStore?.generalFilterSetup?.itemsToShow1400}
        cardWidthTablet={dataStore?.generalFilterSetup?.cardWidthTablet}
        cardWidthDesktop={dataStore?.generalFilterSetup?.cardWidthDesktop}
        withoutSelectedState={
          dataStore?.generalFilterSetup?.withoutSelectedState
        }
      />

      <SliderFeaturedProducts
        state={dataStore?.sliderFeaturedProducts?.state}
        variant={dataStore?.sliderFeaturedProducts?.variant}
        title={dataStore?.sliderFeaturedProducts?.title}
        images={dataStore?.sliderFeaturedProducts?.images}
        products={dataStore?.sliderFeaturedProducts?.products}
      />

      <BrandsCarousel
        state={brands?.setup?.state}
        carouselRows={brands?.setup?.carouselRows}
        arrowsPosition={brands?.setup?.arrowsPosition}
        title={brands?.setup?.title}
        brands={brands?.brands}
        currentCategory={brands?.setup?.category}
        mainCategory={mainCategory}
      />
      {advertising[0] && (
        <Advertising
          state={advertising[0]?.state}
          imageUrlMobile={advertising[0]?.imageUrlMobile}
          imageUrlDesktop={advertising[0]?.imageUrlDesktop}
          name={advertising[0]?.name}
          url={advertising[0]?.url}
          ext={advertising[0]?.ext}
        />
      )}
      <section
        style={getBackground(
          selectedFilter?.color,
          dataStore?.featuredProductsSection?.styles?.backgroundImage,
          dataStore?.featuredProductsSection?.allSectionStyles,
        )}
      >
        {/* Categorias "¡Especialista!" */}
        <CategoriesMenu
          state={categoriesSetup?.setup?.state}
          carouselRows={categoriesSetup?.setup?.carouselRows}
          titlePosition={categoriesSetup?.setup?.titlePosition}
          title={categoriesSetup?.setup?.title}
          subtitle={categoriesSetup?.setup?.subtitle}
          categories={categoriesData?.categories}
        />

        {/* Cateforias "seleccione la categoría de su interés" */}

        {dataStore?.generalfilter[defCta]?.destacados?.status && (
        <div style={{ marginBottom: '53px' }}>
          <FeaturedProductsSection
            sectionTitle={dataStore?.featuredProductsSection?.sectionTitle}
            onClick={handleFeaturedClick}
            handleBannersClick={handleBannersClick}
            featuredProducts={
              dataStore?.generalfilter[defCta]?.destacados?.destacado[0]
            }
            cta={defCta}
            id={1}
            inlineStyles={{
              ...dataStore?.featuredProductsSection?.styles,
              marginTop: store === 'constructor' ? '60px' : '0px',
            }}
          />

          <FeaturedProductsSection
            onClick={handleFeaturedClick}
            handleBannersClick={handleBannersClick}
            featuredProducts={
              dataStore?.generalfilter[defCta]?.destacados?.destacado[1]
            }
            cta={defCta}
            id={2}
            inlineStyles={dataStore?.featuredProductsSection?.styles}
          />

          <FeaturedProductsSection
            onClick={handleFeaturedClick}
            handleBannersClick={handleBannersClick}
            featuredProducts={
              dataStore?.generalfilter[defCta]?.destacados?.destacado[2]
            }
            cta={defCta}
            id={3}
            inlineStyles={{ ...dataStore?.featuredProductsSection?.styles }}
          />
        </div>
        )}

        <ImageMatrix
          state={imageMatrix?.state}
          content={imageMatrix?.content}
          onClick={handleImageMatrixClick}
          title={imageMatrix?.title}
          styles={imageMatrix?.styles}
        />

        {advertising[1] && (
          <Advertising
            state={advertising[1]?.state}
            imageUrlMobile={advertising[1]?.imageUrlMobile}
            imageUrlDesktop={advertising[1]?.imageUrlDesktop}
            name={advertising[1]?.name}
            url={advertising[1]?.url}
            ext={advertising[1]?.ext}
          />
        )}
      </section>

      <BrandsCarousel
        state={dataStore?.secondBrandsCarrusel?.state}
        carouselRows={dataStore?.secondBrandsCarrusel?.carouselRows}
        arrowsPosition={dataStore?.secondBrandsCarrusel?.arrowsPosition}
        title={dataStore?.secondBrandsCarrusel?.title}
        brands={dataStore?.secondBrandsCarrusel?.brands}
        currentCategory={dataStore?.secondBrandsCarrusel?.category}
        mainCategory={mainCategory}
      />

      <Advertising
        state={dataStore?.secondAdvertising?.state}
        name={dataStore?.secondAdvertising?.name}
        imageUrlDesktop={dataStore?.secondAdvertising?.imageUrlDesktop}
        imageUrlMobile={dataStore?.secondAdvertising?.imageUrlMobile}
        url={dataStore?.secondAdvertising?.url}
        ext={dataStore?.secondAdvertising?.ext}
      />
      <FullWidthInfoHome
        backgroundColor={dataStore?.secondfullWidthInfoHome?.backgroundColor}
        banner1={dataStore?.secondfullWidthInfoHome?.banner1}
        banner2={dataStore?.secondfullWidthInfoHome?.banner2}
        direction={dataStore?.secondfullWidthInfoHome?.direction}
        imagesCarousel={dataStore?.secondfullWidthInfoHome?.imagesCarousel}
        imagesCarouselB={dataStore?.secondfullWidthInfoHome?.imagesCarouselB}
        state={dataStore?.secondfullWidthInfoHome?.state}
        variant={dataStore?.secondfullWidthInfoHome?.variant}
        theme={theme}
      />
      <ImageMatrix
        content={dataStore?.secondImageMatrix?.content}
        onClick={handleImageMatrixClick}
        state={dataStore?.secondImageMatrix?.state}
        title={dataStore?.secondImageMatrix?.title}
        styles={dataStore?.secondImageMatrix?.styles}
      />

      {/* Nuestros servicios */}
      <SectionGroup
        state={dataStore?.sectionGroup?.state}
        backgroundColor={dataStore?.sectionGroup?.backgroundColor}
        marginBottom={dataStore?.sectionGroup?.marginBottom}
        smallHeader={dataStore?.sectionGroup?.smallHeader}
        embedVideo={dataStore?.sectionGroup?.embedVideo}
        servicesTabs={dataStore?.sectionGroup?.servicesTabs}
        advertising={dataStore?.sectionGroup?.advertising}
        theme={theme}
        onClick={handleBannersClick}
      />

      {/* Mapa */}
      <TabsMaps
        data={dataStore?.tabsMaps?.data}
        state={dataStore?.tabsMaps?.state}
        defaultCity={dataStore?.tabsMaps?.defaultCity}
        button={dataStore?.tabsMaps?.button}
        sectionTitle={dataStore?.tabsMaps?.sectionTitle}
        backgroundImage={dataStore?.tabsMaps?.backgroundImage}
        theme={theme}
      />

      <Advertising
        state={dataStore?.thirdAdvertising?.state}
        name={dataStore?.thirdAdvertising?.name}
        imageUrlDesktop={dataStore?.thirdAdvertising?.imageUrlDesktop}
        imageUrlMobile={dataStore?.thirdAdvertising?.imageUrlMobile}
        url={dataStore?.thirdAdvertising?.url}
        ext={dataStore?.thirdAdvertising?.ext}
      />
      <ImageMatrix
        content={dataStore?.thirdImageMatrix?.content}
        onClick={handleImageMatrixClick}
        state={dataStore?.thirdImageMatrix?.state}
        title={dataStore?.thirdImageMatrix?.title}
        styles={dataStore?.thirdImageMatrix?.styles}
      />
      <ImageMatrix
        content={dataStore?.fourthImageMatrix?.content}
        onClick={handleImageMatrixClick}
        state={dataStore?.fourthImageMatrix?.state}
        title={dataStore?.fourthImageMatrix?.title}
        styles={dataStore?.fourthImageMatrix?.styles}
      />
      {advertising[2] && (
        <Advertising
          state={advertising[2]?.state}
          imageUrlMobile={advertising[2]?.imageUrlMobile}
          imageUrlDesktop={advertising[2]?.imageUrlDesktop}
          name={advertising[2]?.name}
          url={advertising[2]?.url}
          ext={advertising[2]?.ext}
        />
      )}
      {/* Disfrute de los beneficios */}
      <InformativeCardsGroup
        content={dataStore?.InformativeCardsGroup?.content}
        state={dataStore?.InformativeCardsGroup?.state}
        title={dataStore?.InformativeCardsGroup?.title}
        variant={dataStore?.InformativeCardsGroup?.variant}
        theme={theme}
      />
      <StoreDescription
        state={dataStore?.StoreDescription?.state}
        title={dataStore?.StoreDescription?.title}
        variant={dataStore?.StoreDescription?.variant}
        content={dataStore?.StoreDescription?.content}
      />
      <FullWidthInfoHome
        backgroundColor={dataStore?.thirdfullWidthInfoHome?.backgroundColor}
        banner1={dataStore?.thirdfullWidthInfoHome?.banner1}
        banner2={dataStore?.thirdfullWidthInfoHome?.banner2}
        direction={dataStore?.thirdfullWidthInfoHome?.direction}
        imagesCarousel={dataStore?.thirdfullWidthInfoHome?.imagesCarousel}
        imagesCarouselB={dataStore?.thirdfullWidthInfoHome?.imagesCarouselB}
        state={dataStore?.thirdfullWidthInfoHome?.state}
        variant={dataStore?.thirdfullWidthInfoHome?.variant}
        theme={theme}
      />
      <ImageMatrix
        content={dataStore?.fifthImageMatrix?.content}
        onClick={handleImageMatrixClick}
        state={dataStore?.fifthImageMatrix?.state}
        title={dataStore?.fifthImageMatrix?.title}
        styles={dataStore?.fifthImageMatrix?.styles}
      />
      {advertising[3] && (
        <Advertising
          state={advertising[3]?.state}
          imageUrlMobile={advertising[3]?.imageUrlMobile}
          imageUrlDesktop={advertising[3]?.imageUrlDesktop}
          name={advertising[3]?.name}
          url={advertising[3]?.url}
          ext={advertising[3]?.ext}
        />
      )}
      {/* Últimos Blog */}
      <LastBlogSection
        state={dataStore?.lastBlogSection?.state}
        title={dataStore?.lastBlogSection?.title}
        text={dataStore?.lastBlogSection?.text}
        buttonUrl={dataStore?.lastBlogSection?.buttonUrl}
        variant={dataStore?.lastBlogSection?.variant}
        cardsFontSizeTitle={dataStore?.lastBlogSection?.cardsFontSize}
        cardsStrongTitle={dataStore?.lastBlogSection?.cardsStrongTitle}
        cardsFontSizeDescription={
          dataStore?.lastBlogSection?.cardsFontSizeDescription
        }
        cardsStrongDescription={
          dataStore?.lastBlogSection?.cardsStrongDescription
        }
        cardsButtonVariant={dataStore?.lastBlogSection?.cardsButtonVariant}
        cardsButtonColor={dataStore?.lastBlogSection?.cardsButtonColor}
        cardsButtonRounded={dataStore?.lastBlogSection?.cardsButtonRounded}
        blogs={dataStore?.lastBlogSection?.blogs}
        theme={theme}
        styles={dataStore?.lastBlogSection?.styles}
        onClick={handleBannersClick}
        colorContent={dataStore?.lastBlogSection?.colorContent}
      />

      <FrequentQuestionsComponent
        state={dataStore?.frequentQuestionsComponent?.state}
        data={dataStore?.frequentQuestionsComponent?.data}
        itemSelected={dataStore?.frequentQuestionsComponent?.itemSelected}
      />

      <LegalComponent
        state={dataStore?.legalComponent?.state}
        content={dataStore?.legalComponent?.content}
        backgroundColor={dataStore?.legalComponent?.backgroundColor}
      />
    </>
  );
};

HomeTemplate.propTypes = {
  initialSetUp: PropTypes.shape({}),
};

export default HomeTemplate;

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Typography from '../../../atoms/Typography/Typography';
import CounterSelectorBox from '../../CounterSelectorBox/CounterSelectorBox';
import styles from './AddToCartSuccess.module.scss';
// import { ReactComponent as Success } from '../../../../../assets/svg/CircleSuccess.svg';
import { CloudeFlare } from '../../../../../constants';
import useBadges from '../../../../Hooks/useBadges';
import usePrices from '../../../../Hooks/usePrices/usePrices';
import { logicalBadge4x3 } from '../../../../../utils';
import DiscountBadge from '../../../atoms/DiscountBadge/DiscountBadge';

const AddToCartSuccess = ({
  count,
  product,
  selectorlabel,
  onClick,
  response,
  services,
  warranty,
  isPromotion4x3,
}) => {
  if (!response?.variants || response.variants.length === 0) {
    return null;
  }
  const { badges } = useBadges(response?.variants[0], services, warranty);
  const { auxPrice, regularPrice } = usePrices(response?.variants[0], badges);
  const store = useSelector((state) => state.transversalSlice.store);
  useEffect(() => {
    console.log('store', store);
  }, [store]);

  return (
    <div className={styles.container}>
      <div className={styles.product}>
        <div className={styles.root__image}>
          <img
            src={`${CloudeFlare}/${product?.id}/thumbnail`}
            alt={product?.variants[0]?.name}
          />
          {isPromotion4x3 && store === 'grandesvolumenes' && (
          <DiscountBadge
            className={styles.root__badge}
            state={badges ? logicalBadge4x3(badges) : ''}
            label={badges ? logicalBadge4x3(badges) : ''}
          />
          )}
        </div>
        <div className={styles.info}>
          <Typography className="name">{product?.variants[0]?.name}</Typography>
          <Typography className="price">{`${regularPrice.symbol} ${regularPrice.price} ${regularPrice.unit}`}</Typography>
          {auxPrice.price && (
            <Typography className="normal">
              {`${auxPrice.symbol} ${auxPrice.price} ${auxPrice.unit}`}
            </Typography>
          )}
        </div>
      </div>
      {!isPromotion4x3 && (
        <div className={styles.counter}>
          <Typography>{selectorlabel}</Typography>
          <CounterSelectorBox count={count} onClick={onClick} />
          {/* {state && (
          <div className={styles.state}>
            <Success />
            {state && 'Agregado'}
          </div>
        )} */}
        </div>
      )}
    </div>
  );
};

AddToCartSuccess.propTypes = {
  count: PropTypes.number,
  selectorlabel: PropTypes.string,
  onClick: PropTypes.func,
  product: PropTypes.shape({
    id: PropTypes.string,
    variants: PropTypes.arrayOf({
      name: PropTypes.string,
    }),
  }),
  regularPrice: PropTypes.shape({
    price: PropTypes.string,
    symbol: PropTypes.string,
    unit: PropTypes.string,
  }),
  auxPrice: PropTypes.shape({
    price: PropTypes.string,
    symbol: PropTypes.string,
    unit: PropTypes.string,
  }),
  response: PropTypes.shape({
    variants: PropTypes.arrayOf({}),
  }),
  warranty: PropTypes.arrayOf({}),
  services: PropTypes.arrayOf({}),
  isPromotion4x3: PropTypes.bool,
};

export default AddToCartSuccess;

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const MetaTags = ({ store, restUrl, description }) => {
  const stores = {
    constructor:
      'https://www.homecenter.com.co/homecenter-co/tiendas/constructor/',
    petcenter:
      'https://www.homecenter.com.co/homecenter-co/tiendas/tienda-mascotas-pet-center/',
    carcenter:
      'https://www.homecenter.com.co/homecenter-co/tiendas/servicio-automotriz-carcenter/',
    grandesvolumenes:
      'https://www.homecenter.com.co/homecenter-co/tiendas/compras-por-mayor-grandes-volumenes/',
  };

  return (
    <Helmet>
      <link rel="canonical" href={`${stores[store]}${restUrl}`} />
      <meta name="description" content={description} />
    </Helmet>
  );
};
MetaTags.propTypes = {
  store: PropTypes.string,
  restUrl: PropTypes.string,
  description: PropTypes.string,
};

export default MetaTags;

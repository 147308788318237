/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import style from './withTooltip.module.scss';

const withTooltip = (WrappedComponent) => {
  const WithTooltip = (props) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleMouseEnter = () => {
      setShowTooltip(true);
    };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

    return (
      <div
        className={style.withTooltip}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {showTooltip && <div className={style.withTooltip__tooltip}>{props.tooltipText}</div>}
        <WrappedComponent {...props} />
      </div>
    );
  };

  return WithTooltip;
};

export default withTooltip;

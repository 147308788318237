/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { render } from '@testing-library/react';
import Typography from '../../atoms/Typography/Typography';
import styles from './ProductPriceBoxPDP.module.scss';

const ProductPriceBoxPDP = ({ ...props }) => {
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    if (props.productPrices && props.selectedVariantIndex !== undefined) {
      setPrices(props.productPrices[props.selectedVariantIndex] || []);
    }
  }, [props.productPrices, props.selectedVariantIndex]);
  useEffect(() => {
    console.log('Prices', prices);
  }, [prices]);

  const priceBox = props?.productUnitPrice?.filter((item) => item.unit === 'caja');
  const m2Price = prices?.find((item) => item.type === 'M2' || item.type === 'METRO CUADRADO.');
  const metroprice = m2Price?.unit === 'METRO' ? `${m2Price.symbol}${m2Price.price} METRO` : '';
  const m2fullprice = m2Price ? `${m2Price.symbol}${m2Price.price} ${m2Price.unit}` : '';
  const normalPrice = prices?.find((item) => item.type === 'NORMAL');
  const normalfullprice = normalPrice ? `${normalPrice.symbol}${normalPrice.price} ${normalPrice.unit}` : '';
  const internetprice = prices?.filter((item) => item.type === 'INTERNET') || [];
  const internetpricem2 = internetprice?.find((item) => item.unit === 'm²');
  const internetpricenormal = internetprice?.find((item) => item.unit === 'caja' || item.unit === 'und');
  const internetFullpricem2 = internetpricem2 ? `${internetpricem2.symbol}${internetpricem2.price} m²` : '';
  const internetFullpricenormal = internetpricenormal ? `${internetpricenormal.symbol}${internetpricenormal.price} ${internetpricenormal.unit}` : '';
  const cmrPrice = prices?.filter((item) => item.type === 'CMR') || [];
  const cmrNormalPrice = cmrPrice?.find((item) => item.unit === 'und' || item.unit === 'unidad');
  const cmrNormalFullPrice = cmrNormalPrice ? `${cmrNormalPrice.symbol}${cmrNormalPrice.price} ${cmrNormalPrice.unit}` : '';
  const cmrM2Price = cmrPrice?.find((item) => item.unit === 'm²');
  const cmrM2FullPrice = cmrM2Price ? `${cmrM2Price.symbol}${cmrM2Price.price} ${cmrM2Price.unit}` : '';
  const cmrFullPrice = cmrPrice ? `${cmrPrice.symbol}${cmrPrice.price} ${cmrPrice.unit}` : '';
  const preciosPro = prices?.find((item) => item.type === 'PRECIOS_PRO');
  const preciosProFullPrice = preciosPro ? `${preciosPro.symbol}${preciosPro.price} ${preciosPro.unit}` : '';
  const preciosproM = prices?.find((item) => item.type === 'M2_PRECIOS_PRO');
  const preciosProFullPriceM = preciosproM ? `${preciosproM.symbol}${preciosproM.price} ${preciosproM.unit}` : '';

  useEffect(() => {
    console.log('Prices', normalPrice, internetprice);
  }, [normalPrice]);
  const renderPrice = () => {
    if (Array.isArray(cmrPrice) && cmrPrice.length > 0) {
      console.log('cmrPrice', cmrPrice);
      if (cmrM2Price !== undefined && cmrM2Price !== null) {
        console.log('cmrM2Price', cmrM2Price);
        return (
          <>
            <Typography variant="h4" className={styles.priceProductBox__price}>
              {cmrM2FullPrice}
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {cmrNormalFullPrice}
            </Typography>
          </>
        );
      }
      console.log('cmrFullPrice', cmrNormalFullPrice);
      return (
        <Typography variant="h4" className={styles.priceProductBox__price}>
          {cmrNormalFullPrice}
        </Typography>
      );
    }
    if (internetprice !== undefined && internetprice.length > 0) {
      console.log('internetprice', internetprice);
      if (internetpricem2) {
        return (
          <>
            <Typography variant="h4" className={styles.priceBox}>
              {internetFullpricem2}
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {internetFullpricenormal}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="h4" className={styles.priceProductBox__price}>
          {internetFullpricenormal}
        </Typography>
      );
    }
    if (preciosPro !== undefined && preciosPro !== null) {
      console.log('preciosPro', preciosPro);
      if (preciosproM !== undefined && preciosproM !== null) {
        return (
          <>
            <Typography variant="h4" className={styles.priceProductBox__price}>
              {m2fullprice}
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {normalfullprice}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="h4" className={styles.priceProductBox__price}>
          {preciosProFullPrice}
        </Typography>
      );
    }
    if (priceBox && priceBox.length > 0) {
      return (
        <>
          <Typography variant="h4" className={styles.priceProductBox__price}>
            {m2fullprice}
          </Typography>
          <Typography variant="delta29" className={styles.priceBox}>
            |
          </Typography>
          <Typography variant="delta29" className={styles.priceBox}>
            {`${priceBox[0].price} ${priceBox[0].unit}`}
          </Typography>
        </>
      );
    }
    if (normalPrice !== undefined && normalPrice !== null) {
      if (m2Price !== undefined && m2Price !== null) {
        if (metroprice !== undefined && metroprice.length > 0) {
          console.log('metroprice', metroprice);
          return (
            <>
              <Typography variant="h4" className={styles.priceProductBox__price}>
                {metroprice}
              </Typography>
              <Typography variant="delta29" className={styles.priceBox}>
                |
              </Typography>
              <Typography variant="delta29" className={styles.priceBox}>
                {normalfullprice}
              </Typography>
            </>
          );
        }
        return (
          <>
            <Typography variant="h4" className={styles.priceProductBox__price}>
              {m2fullprice}
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {normalfullprice}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="h4" className={styles.priceProductBox__price}>
          {normalfullprice}
        </Typography>
      );
    }
    return null;
  };
  const renderAuxiliaryPrice = () => {
    if (Array.isArray(cmrPrice) && cmrPrice.length > 0) {
      console.log('cmrPriceAux', cmrPrice);
      if (m2Price !== undefined && m2Price !== null) {
        return (
          <>
            <Typography variant="epsilon24" className={styles.priceBox}>
              {metroprice || m2fullprice}
            </Typography>
            <Typography variant="epsilon24" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="epsilon24" className={styles.priceBox}>
              {normalfullprice}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="epsilon24" className={styles.priceBox}>
          {normalfullprice}
        </Typography>
      );
    }
    if (internetprice !== undefined && internetprice.length > 0) {
      console.log('internetpriceAux', internetprice);
      if (internetpricem2 !== undefined && internetpricem2 !== null) {
        return (
          <>
            <Typography
              variant="epsilon24"
              className={`${styles.priceProductBox__unitPrice} ${
                props.subAux ? styles.priceProductBox__unitPrice__nosub : ''
              }`}
            >
              {m2fullprice}
            </Typography>
            <Typography variant="epsilon24" className={styles.priceProductBox__unitPrice}>
              |
            </Typography>
            <Typography
              variant="epsilon24"
              className={`${styles.priceProductBox__unitPrice} ${
                props.subAux ? styles.priceProductBox__unitPrice__nosub : ''
              }`}
            >
              {normalfullprice}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="epsilon24" className={styles.priceProductBox__unitPrice}>
          {normalfullprice}
        </Typography>
      );
    }
    if (preciosPro !== undefined && preciosPro !== null) {
      console.log('preciosProAux', preciosPro);
      if (preciosproM !== undefined && preciosproM !== null) {
        return (
          <>
            <Typography variant="h4" className={styles.priceProductBox__price}>
              {preciosProFullPriceM}
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              |
            </Typography>
            <Typography variant="delta29" className={styles.priceBox}>
              {preciosProFullPrice}
            </Typography>
          </>
        );
      }
      return (
        <Typography variant="delta29" className={styles.priceBox}>
          {normalfullprice}
        </Typography>
      );
    }
    return null;
  };
  return (
    <div className={styles.priceProductBox__container}>
      <div className={styles.badgesContainer}>
        {props.discount && (
        <div style={{
          display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center',
        }}
        >
          <img className={styles.badge} src={props.badgeImage} alt="" />
          <span className={styles.discount}>{props.discount}</span>
        </div>
        )}

        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderPrice()}
        </div>
        <Typography
          variant="epsilon24"
          className={styles.priceProductBox__savedTitle}
        >
          {props.savedTitle}
        </Typography>
        {props.subAux && (
        <Typography variant="omega17" className={styles.priceProductBox__subAux}>
          {props.subAux}
        </Typography>
        )}
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderAuxiliaryPrice()}
        </div>
        {props.multiPurposeBadgeImage && (
        <div className={`store_in_store_${props.multiPurposeBadgeImage.type}`}>
          <img
            src={props.multiPurposeBadgeImage.value}
            alt={props.multiPurposeBadgeImage.name}
          />
          <Typography
            variant="kappa14"
            style={{ fontWeight: 'bold' }}
          >
            {props.multiPurposeBadgeImage.name}
          </Typography>
        </div>
        )}
      </div>
    </div>
  );
};

ProductPriceBoxPDP.propTypes = {
  badgeImage: PropTypes.string,
  subAux: PropTypes.string,
  discount: PropTypes.string,
  price: PropTypes.string,
  priceType: PropTypes.string,
  savedTitle: PropTypes.string,
  unitPrice: PropTypes.string,
  multiPurposeBadgeImage: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
  }),
  productUnitPrice: PropTypes.shape({}),
  productPrices: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        boxCoverage: PropTypes.string,
        label: PropTypes.string,
        price: PropTypes.string,
        symbol: PropTypes.string,
        type: PropTypes.string,
        unit: PropTypes.string,
      }),
    ),
  ),
  selectedVariantIndex: PropTypes.number,
};

export default ProductPriceBoxPDP;
